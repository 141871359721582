import React from "react";
import { Container, Typography, Paper } from "@mui/material";
import Cry from "./cry";

import BooksList from "./books";
import ShowCards from "./showcards";

function mw() {
  return <div>{<Cry />}</div>;
}

export default mw;
